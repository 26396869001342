/* Base soft purple color */
:root {
  --soft-purple: rgba(66, 49, 115, 0.69);
  --darker-soft-purple: #8b7ea8;
  --lighter-soft-purple: #b6a8cc;
  --active-text-color: #333; /* Dark text for contrast */
  --inactive-text-color: #f5f5f5; /* Light text for inactive state */
  --very-light-purple: rgba(220, 206, 245, 0.91); /* Example of a very light purple color */
  /*background-color: var(--soft-purple);*/
  background-image: url(../public/background.png);
  background-size: cover;
}


/* Login detail */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-modal-content {
    background-color: rgba(220, 206, 245, 0.8);
    padding: 5px;
    border-radius: 5px;
    background-size: cover;
    background-blend-mode: multiply;
}
.form-container {
  display: flex;
  background-image: url(./images/login_background_new_crop.webp);
  justify-content: center;
  align-items: center;
  height: 300px;
  width: 15vw;
  margin: auto;

}

.form-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px;

}
form button:hover {
  background-color: #9b83c0; /* A slightly darker shade for hover */
}
form button{
  background-color: #b6a8cc; /* Your theme color */
  color: white; /* Adjust text color as needed */
  border-radius: 4px;
  cursor: pointer;
  padding: 5px 5px;
  margin: 5px;
  transition: background-color 0.3s;
  border: none;
}


.form-fields {
  display: flex;
  flex-direction: column;
  gap: 10px; /* Adjust as needed */
}



/* Tab menu styling */
.tab-menu {
  background-color: var(--soft-purple);
  padding: 20px 10px;
  border-radius: 5px 5px 0 0;
}

.tab {
  background-color: var(--soft-purple);
  color: var(--inactive-text-color);
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}
.tab:hover {
  background-color: var(--darker-soft-purple);
  color: var(--active-text-color);
}

.active-tab{
  background-color: var(--lighter-soft-purple);
  color: var(--active-text-color);
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.main-body-container {
  margin: 10px 40px 20px;
}

.tab.active {
  background-color: var(--lighter-soft-purple);
  color: var(--active-text-color);
}
.tab-content {
  background-color: var(--very-light-purple);
  padding: 20px; /* Increased padding for more space inside */
  margin: 5px auto; /* Margin to create space outside the element */
  border-radius: 10px; /* Rounded corners */

  /* Other styles like shadows for a raised effect */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Example shadow */
}

/* Time frame buttons styling */
.time-frame-buttons {
  background-color: rgba(101, 49, 91, 0.69);
}

.time-button {
  background-color: rgba(0, 0, 0, 0.0);
  color: white;
  border: none;
  margin: 5px;
  padding: 5px 2px;
  cursor: pointer;
  font-size: 10px;
}
.time-button:hover {
  background-color: rgba(101, 49, 91, 0.2)
}


.time-button-active {
  color: rgba(192, 190, 53, 0.89);
  background-color: rgba(0, 0, 0, 0.0);
  outline: none;
  border: none;
  text-decoration: underline;
  font-size: 10px;
}

.dropdown-container {
  position: absolute;
  right: 35px;
  padding: 15px;
}
.dropdown-label {
  margin-right: 10px;
}
.dropdown-select {
  width: 150px;
}

.frontpage-container {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center children horizontally */
  justify-content: flex-start; /* Align children to the start vertically */
  width: 100%; /* Take full width */
  min-height: 100vh; /* Optional: take at least full viewport height */
}

.total-score{
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  height: 500px;
  width: 900px;
}

.contract-pie-chart {
  display: flex;
  height: 350px;
  width: 450px;

}

.role-performance {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  height: 40px;
  width: 200px;
}

.loader-container
{
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  height: 100%;
  width: 100%;
}
/* Loader styling */
.loader {
  border: 4px solid #f3f3f3; /* Light color */
  border-top: 4px solid #3498db; /* Blue color */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.table {
  display: flex;
  flex-direction: column;
  width: 100%; /* Adjust the width as needed */
  max-width: 300px; /* Optional: Set a maximum width */
  margin: auto; /* Center the table */
}

.row {
  display: flex;
  justify-content: space-around; /* Adjust spacing between columns */
  align-items: center;
  padding: 3px;
}

.cell {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1px 1px; /* Adjust margin for spacing between role cells */
  border: 1px solid #cecaca; /* Example: 1px solid black border */
  width: auto;
  border-radius: 5px;

}

.role-name {
  font-weight: bold;
  padding: 5px;
  margin-bottom: 1px; /* Space between name and performance */
}

/* OverUnderTricks.css */

.over-under-tricks-container {
  display: flex;
  height: auto; /* Let the container grow as needed */
  width: 60%;
  margin-bottom: 5px;
}

.histogram {
  flex: 1;
  padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Align items to the start of the container */
}

.performance-container {
  display: flex;
}

.performance-title {
  flex: 2;
  margin: 5px;
}


