/* Header styling */
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background-color: rgba(101, 49, 91, 0.5);
}

.header-logo {
    margin-left: 5px;
    margin-top: 5px;
    height: 110px;
    width: 220px;
}

.welcome-label {
    padding: 10px;
}

.header-input {
    margin-right: 10px;
}

.header-controls {
    display: flex;
    align-items: center;
    margin-right: 10px;
}

.header-button {
    background-color: #b6a8cc; /* Your theme color */
    color: white; /* Adjust text color as needed */
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 10px;
    transition: background-color 0.3s;
}

.header-button:hover {
    background-color: #9f91b5; /* A slightly darker shade for hover */
}