.bid-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
}

.bid-performance-container {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    width: 40vw;
    height: 40vh;
}

.contractype-bid-chart {
    height: 30vh;
}

.main-bid-chart {
    flex: 1;
    height: 60vh;
}

.gametype-bid-chart {
    height: 30vh;
}
.good-bad-bid-chart {
    height: 30vh;
}

.main-bid-graph, .contract-bid-performance, .type-bid-performance, .declarer-bid-performance, .defender-bid-performance {
    /* Common styles for each graph container */
    margin: 10px;
    /* other styles as needed */
}

.contract-bid-performance, .type-bid-performance, .declarer-bid-performance, .defender-bid-performance {
    flex: 1;
    align-content: center;
    /* Adjust width as necessary */
}

.point-chart-container {
    display: flex;
    justify-content: space-around;
    width: 100%;
}

.point-bar-chart {
    flex: 1;
    width: 30%;
    height: 20vh;
}

