.settings-modal-content {
    width: 40%; /* Set the width to 40% of the viewport */
    max-height: 40vh; /* Set a maximum height */
    overflow-y: auto; /* Add a scrollbar when the content exceeds the maximum height */
    background-color: #8b7ea8;
    background-image: linear-gradient(315deg, #8b7ea8 0%, #6a757b 74%);
    /* ...existing styles... */
}


.settings-group {
    border: 1px solid lightgray;
    padding: 10px;
    margin: 10px 0;
    border-radius: 5px;
}

.settings-group p {
    margin: 1px;
}

/* The emerging W3C standard that is currently Firefox-only */
* {
  scrollbar-width: thin;
  scrollbar-color: var(--darker-soft-purple) var(--soft-purple);
}

/* Works on Chrome/Edge/Safari */
*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: var(--soft-purple);
}

*::-webkit-scrollbar-thumb {
  background-color: var(--darker-soft-purple);
  border-radius: 20px;
  border: 3px solid var(--soft-purple);
}