/* landingPage.css */
.landing-page {
    font-family: Arial, sans-serif;
}

.landing-header {
    text-align: center;
    padding: 20px 0;
}

.landing-main {
    text-align: center;
    padding: 40px 20px;
}

.main-cta {
    padding: 10px 20px;
    margin-top: 20px;
    cursor: pointer;
}

.features-section {
    display: flex;
    justify-content: space-around;
    padding: 40px 20px;
    margin: 5px
}

.feature h3 {
    margin-bottom: 10px;
}

.feature {
    margin: 4px;
    padding: 5px;
}

.landing-footer {
    text-align: center;
    padding: 20px 0;
    border-top: 1px solid #ccc;
}