.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 959;
}

.modal {
    position: fixed;
    top: 20%;
    left: 20%;
    right: 20%;
    background-color: rgba(220,206,245,0.76);
    padding: 20px;
    border: 1px solid black;
    z-index: 1000;
    min-width: 600px;
    max-width: 800px;
}


.compass-layout {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}
.board-info {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #fff; /* or any color you prefer */
    padding: 10px;
    border: 1px solid #ddd; /* optional */
    border-radius: 5px; /* optional */
    font-size: 10px;
}
.board-info p{
    margin: 2px;
    padding: 2px;
}

.compass-center {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.compass-direction {
    flex: 1;
    margin: 2px;
    padding: 2px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 12px;
    position: relative;
}
.compass-direction p {
    margin: 1px;
    padding: 1px;
}

.north, .south {
    width: 5vw;
}

.west, .east {
    flex: 1;
}

.double-dummy {
   font-size: 11px;
}
.double-dummy p {
    margin: 1px;
    padding: 1px;
}
.highlight-contract {
    background-color: #696969;
}

.highlight {
    background-color: #f0f0f0;
    border: 2px solid #ffa500;
    /* Additional styles for highlighting */
}

.toolbar {
    /* Add your styling here */
    z-index: 1001;
    background-color: #cccccc;
    width: 10vw;
    padding: 5px;
}

.modal-hand-details-container {
    width: 80%;
    text-align: center;
    overflow-y: auto;
    max-height: calc(7 * 3em); /* Adjust this based on the size of your items */
    padding: 5px;
    margin: auto;
}
/* Style the scrollbar */
.modal-hand-details-container::-webkit-scrollbar {
    width: 10px; /* Width of the scrollbar */
}

/* Style the scrollbar handle (thumb) */
.modal-hand-details-container::-webkit-scrollbar-thumb {
    background: var(--darker-soft-purple); /* Color of the scroll thumb */
    border-radius: 5px; /* Radius for the scroll thumb */
}

/* Style the scrollbar track */
.modal-hand-details-container::-webkit-scrollbar-track {
    background: var(--lighter-soft-purple); /* Color of the scroll track */
    border-radius: 5px; /* Radius for the scroll track */
}

.modal-hand-details-header th {
    position: sticky;
    top: 0;
    background: rgba(101, 49, 91, 0.69); /* To prevent cells from showing through */
}

.modal-board-grouping {
    height: 50px;
}

